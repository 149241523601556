// fonts
$font-family-inter: 'Inter', sans-serif;
$inter-bold: 700;
$inter-semi-bold: 600;
$inter-regular: 400;
$inter-light: 300;

// colors
$bg-blue: #130C27;
$grey-font: #898693;
$purple: #9D94F5;
$white: #FFF;
$white-hover: #c2bfe6;
$purple-gradient: linear-gradient(180deg, #9D94F5 0%, #756AEC 100%);
$pink-gradient: linear-gradient(324deg, rgba(174, 99, 169, 0.3) 0%, rgba(49, 31, 67, 0.3) 100%);
$nav-gradient: linear-gradient(180deg, #1D1534 0%, #130C27 100%);
$placeholder-font: #281839;
