@import './variables';

.heading {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  color: white;
  line-height: normal;
  font-size: 50px;
  @media (min-width: 768px) {
    font-size: 88px;
  }
}

.subheading {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  font-size: 52px;
  color: white;
}

.title {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  font-size: 32px;
  color: white;
}

.subtitle-bold {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  font-size: 24px;
  color: white;
}

.subtitle-regular {
  font-family: $font-family-inter;
  font-weight: $inter-regular;
  font-size: 24px;
  color: white;
}

.bold-grey-text {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  font-size: 18px;
  color: $grey-font;
}

.bold-gradient-text {
  font-family: $font-family-inter;
  font-weight: $inter-bold;
  font-size: 18px;
  background: $purple-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.grey-text {
  font-family: $font-family-inter;
  font-weight: $inter-regular;
  font-size: 18px;
  color: $grey-font;
}

.paragraph {
  font-family: $font-family-inter;
  font-weight: $inter-semi-bold;
  font-size: 16px;
  color: white;
}

.placeholder {
  /* in textinput.scss also */
  font-family: $font-family-inter;
  font-weight: $inter-regular;
  font-size: 16px;
  color: $placeholder-font;
}

.footer {
  font-family: $font-family-inter;
  font-weight: $inter-light;
  font-size: 14px;
  color: white;
}

.sidebar-text {
  font-family: $font-family-inter;
  font-weight: $inter-semi-bold;
  font-size: 18px;
  color: $grey-font;
  display: inline-flex;
  width: auto;
  &:hover {
    color: $white-hover;
  }
}

.sidebar-text-active {
  font-family: $font-family-inter;
  font-weight: $inter-semi-bold;
  font-size: 18px;
  color: white;
}

