@import '../../styles/main';

.button-filled {
  background: $purple-gradient;
  padding: 7px 25px;
}

.button-unfilled {
  background: transparent;
  border: 2px solid $purple;
  padding: 5px 25px;
}

.play-icon {
  fill: white; 
}

.button-filled:hover, .button-unfilled:hover {
  .play-icon {
    fill: $white-hover;
  }
  color: $white-hover;
}
