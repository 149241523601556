@import '../../styles/main';

nav {
    background: $nav-gradient;
}

.bg-color {
    background-color: $bg-blue;
    z-index: 999;
}
  