.slide-in-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-in-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms, transform 500ms;
  }
  .slide-in-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-in-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 500ms;
  }
  
  .slide-out-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-out-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 500ms, transform 500ms;
  }
  .slide-out-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-out-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms, transform 500ms;
  }
  