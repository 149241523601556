@import '../../styles/main';

.card {
  background: $pink-gradient;
  border: 1px solid rgba(255,255,255, .3);
  padding: 40px;
  width: 324px;
  height: auto;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 360px) {
    width: 100%;
    padding: 40px 15px;
    align-items: center;
    margin: 8px auto;
  }
}
