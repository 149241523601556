@import './variables';
@import './fonts';

body {
    font-family: $font-family-inter;
}

.page-section {
    margin-top: 350px;
}

/* Landing Transitions */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
  
.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}
  